import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 750.000000 750.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,750.000000) scale(0.100000,-0.100000)"
        >
          <path
            d="M3560 6084 c-168 -20 -285 -40 -402 -69 -461 -116 -867 -351 -1183
-687 -179 -191 -206 -248 -146 -309 23 -22 39 -29 70 -29 47 0 58 9 158 130
192 230 475 442 763 571 648 289 1369 271 2012 -52 282 -141 570 -377 754
-617 108 -140 122 -152 169 -152 54 0 95 39 95 91 0 59 -102 195 -283 377
-394 399 -866 636 -1444 727 -94 15 -487 28 -563 19z"
          />
          <path
            d="M3590 5240 c-390 -54 -705 -211 -969 -483 -95 -98 -117 -132 -105
-165 5 -15 22 -33 37 -40 32 -15 60 -4 91 37 46 60 146 157 221 216 270 210
577 315 920 315 466 0 900 -208 1186 -569 33 -42 67 -84 75 -93 18 -23 66 -23
87 0 37 41 14 90 -113 234 -246 279 -579 465 -955 534 -104 19 -380 27 -475
14z"
          />
          <path
            d="M1240 3885 l0 -355 95 0 95 0 0 130 0 130 95 0 95 0 0 95 0 95 -95 0
-95 0 0 130 0 130 -95 0 -95 0 0 -355z"
          />
          <path d="M1650 3885 l0 -355 95 0 95 0 0 355 0 355 -95 0 -95 0 0 -355z" />
          <path
            d="M2048 4226 c-56 -20 -85 -42 -116 -88 -27 -41 -27 -42 -30 -244 -3
-224 1 -246 65 -306 53 -51 87 -58 268 -58 l165 0 0 90 0 90 -127 1 c-172 2
-187 6 -191 47 l-3 32 160 0 161 0 0 89 0 88 -77 7 c-42 3 -114 6 -160 6 -75
0 -83 2 -83 19 0 50 2 51 166 51 l154 0 0 95 0 95 -157 0 c-105 -1 -170 -5
-195 -14z"
          />
          <path
            d="M3855 4221 c-81 -49 -85 -70 -85 -412 l0 -289 88 0 89 0 6 209 c4
115 5 245 3 288 -3 43 -2 65 0 48 39 -231 79 -418 96 -453 32 -66 85 -97 169
-97 73 0 103 14 143 68 28 38 34 106 35 395 l1 252 -96 0 -96 0 3 -287 c2
-158 1 -270 -3 -248 -4 22 -21 118 -39 213 -33 189 -43 223 -71 264 -43 62
-178 90 -243 49z"
          />
          <path
            d="M2585 4216 c-41 -18 -95 -73 -111 -113 -11 -25 -14 -98 -14 -303 l0
-270 90 0 90 0 0 249 c0 201 3 251 14 260 9 8 48 11 112 9 l99 -3 0 -80 0 -80
-97 -3 -98 -3 0 -89 0 -90 73 0 c39 0 78 -4 84 -8 7 -5 18 -35 24 -68 18 -102
8 -94 120 -94 91 0 100 2 96 18 -3 9 -11 55 -18 101 -9 66 -17 90 -36 109 -24
24 -24 24 -4 38 36 26 54 94 49 191 -5 106 -30 161 -96 208 -43 30 -44 30
-195 32 -111 2 -160 -1 -182 -11z"
          />
          <path
            d="M3245 4211 c-22 -10 -52 -31 -67 -47 -55 -59 -58 -80 -58 -369 l0
-265 90 0 90 0 0 100 0 100 100 0 100 0 0 90 0 90 -100 0 -100 0 0 58 c0 32 5
63 12 70 15 15 172 16 200 1 21 -11 21 -15 14 -265 l-8 -254 96 0 96 0 0 270
c0 294 -3 315 -58 374 -51 54 -93 66 -237 66 -105 0 -138 -4 -170 -19z"
          />
          <path
            d="M4973 4220 c-103 -22 -163 -86 -163 -173 0 -18 20 -96 44 -175 53
-176 54 -175 -62 -170 l-82 3 0 -92 0 -93 113 0 c102 0 118 3 165 26 113 56
124 121 59 329 -31 97 -41 144 -34 153 6 8 38 12 93 12 l84 0 0 90 c0 50 -1
90 -2 90 -75 7 -183 7 -215 0z"
          />
          <path
            d="M5240 3955 c0 -289 3 -311 58 -368 54 -58 88 -67 237 -67 149 0 183
9 237 67 55 57 58 79 58 368 l0 265 -90 0 -90 0 0 -248 c0 -180 -3 -251 -12
-260 -16 -16 -190 -16 -206 0 -9 9 -12 80 -12 260 l0 248 -90 0 -90 0 0 -265z"
          />
          <path
            d="M5999 4197 c-29 -16 -54 -40 -74 -72 l-30 -48 -3 -278 -3 -277 95 -7
c53 -4 151 -6 219 -3 145 5 198 24 243 89 50 73 45 207 -10 258 l-24 23 20 35
c40 67 22 176 -40 239 -51 51 -95 64 -228 64 -107 0 -126 -3 -165 -23z m271
-177 c8 -14 8 -26 0 -40 -9 -17 -21 -20 -85 -20 l-75 0 0 -94 c0 -92 1 -95 23
-94 12 1 52 1 89 0 58 -2 68 -5 73 -22 13 -42 -10 -51 -117 -48 l-100 3 1 159
c1 88 4 163 7 168 3 4 43 8 89 8 74 0 86 -2 95 -20z"
          />
          <path
            d="M2322 3278 c-49 -24 -41 -75 36 -233 224 -457 636 -773 1151 -882
111 -24 149 -27 321 -27 217 -1 333 16 513 75 383 125 717 404 896 749 49 92
52 132 15 156 -46 31 -71 11 -136 -105 -211 -379 -544 -625 -979 -723 -91 -20
-130 -23 -309 -22 -173 0 -221 4 -307 22 -364 79 -688 285 -905 576 -69 92
-152 241 -188 338 -28 76 -60 98 -108 76z"
          />
          <path
            d="M1518 3032 c-38 -23 -46 -72 -24 -137 30 -84 139 -304 200 -403 141
-227 344 -458 551 -625 489 -395 1145 -602 1765 -557 759 56 1425 426 1842
1025 119 172 228 376 228 430 0 18 -12 39 -35 62 -32 32 -40 35 -76 30 -22 -3
-46 -11 -54 -17 -8 -7 -37 -58 -65 -114 -289 -589 -850 -1026 -1502 -1170
-207 -45 -263 -51 -513 -51 -202 1 -260 4 -370 23 -382 66 -723 211 -1025 436
-114 85 -318 280 -407 391 -134 166 -254 371 -343 588 -18 42 -42 84 -53 92
-28 20 -85 19 -119 -3z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
